
import CloudFun, { defineComponent, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import SliderEditor from '@/cloudfun/components/SliderEditor.vue'
import { commafy } from "xe-utils";

export default defineComponent({
  components: {
    SliderEditor,
    Grid
  },
  setup () {
    const model = CloudFun.current?.model

    const grid = ref({})

    var printColumns = [
      { field: 'Code' },
      { field: 'Name' },
      { field: 'Width' },
      { field: 'Height' },
      { field: 'Remark' }
    ]

    const gridOptions: GridOptions = {
      title: '廣告位置',
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      canDelete: false,
      multiselect: false,
      printConfig: { sheetName: '廣告清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '廣告清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        {
          field: 'Code',
          title: '代碼',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: { name: '$input', immediate: true, attrs: { type: 'text' } }
        }, {
          field: 'Name',
          title: '名稱',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: { name: '$input', immediate: true, attrs: { type: 'text' } }
        }, {
          field: 'Width', title: '寬度', width: 80, sortable: true, align: 'right', formatter: ({ cellValue }) => `${commafy(cellValue)}`
        }, {
          field: 'Height', title: '高度', width: 80, sortable: true, align: 'right', formatter: ({ cellValue }) => `${commafy(cellValue)}`
        }, {
          field: 'Remark', title: '備註', resizable: false
        }
      ],
      promises: {
        query: model ? (params) => model.dispatch('bannerPosition/query', params) : undefined,
        queryAll: model ? () => model.dispatch('bannerPosition/query') : undefined,
        save: model ? (params) => model.dispatch('bannerPosition/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true }
    }
    const formOptions = {
      items: [
        {
          field: 'Code',
          title: '代碼',
          span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } }
        }, {
          field: 'Name',
          title: '名稱',
          span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } }
        }, {
          field: 'Width',
          title: '寬度',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入數值', type: 'number', clearable: true } }
        }, {
          field: 'Height',
          title: '高度',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入數值', type: 'number', clearable: true } }
        }, {
          field: 'Remark',
          title: '備註',
          span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } }
        }, {
          span: 24, slots: { default: 'form-banners' }
        },
      ],
      rules: {
        Code: [{ type: 'string', max: 450, required: true }],
        Name: [{ type: 'string', max: 128, required: true }],
        Width: [{ type: 'number', min: 1, max: 3840, required: true }],
        Height: [{ type: 'number', min: 1, max: 2160, required: true }],
        Remark: [{ type: 'string', max: 512 }]
      }
    }

    const sliderWidth = ref('100%')
    const bannerFormOptions = {
      titleWidth: 80,
      titleAlign: 'right',
      items: [
        {
          field: 'Name',
          title: '名稱',
          span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } }
        }, {
          field: 'LinkUrl',
          title: '鏈結網址',
          span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入網址', clearable: true } }
        }, {
          field: 'Remark',
          title: '備註',
          span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } }
        },
      ],
      rules: {
        Name: [{ type: 'string', max: 128 }],
        LinkUrl: [{ type: 'string', max: 2000 }],
        Remark: [{ type: 'string', max: 512 }]
      }
    }

    return {
      grid,
      gridOptions,
      formOptions,
      sliderWidth,
      bannerFormOptions,
      baseUrl: process.env.VUE_APP_BACKEND_URL
    }
  },
  methods: {
    onGridModalResize (size: any) {
      this.sliderWidth = `${size.width - 100}px`
    },
    async onGridEdit (row: any, callback: any) {
      const entity = row.Id ? await this.$model.dispatch('bannerPosition/find', row.Id) : undefined
      if (entity) Object.assign(row, entity)
      callback()
    },
    onGridRemoveSelectedRows (_: any, callback: any) {
      cash('#batch-dropdown').dropdown('hide')
      callback()
    },
    onSliderEdit (image: any, callback: any) {
      image.PositionId = (this.grid as any).editingRow.Id
      callback()
    },
    async onSliderRemove (image: any, callback: any) {
      await this.$model.dispatch('banner/delete', image.Id)
      callback()
    },
    async onSliderSave (params: any, callback: any) {
      await this.$model.dispatch('banner/save', params).then(
        () => {
          this.$model.dispatch('bannerPosition/find', (this.grid as any).editingRow.Id).then(
            (entity) => {
              (this.$refs.sliderEditor as any).reload(entity.Banners)
            },
            reason => { CloudFun.send('error', { subject: '重載失敗', content: reason }) }
          )
        },
        reason => { CloudFun.send('error', { subject: '保存失敗', content: reason }) }
      )
      callback()
    }
  }
})
